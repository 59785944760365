import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  BACK_TO_LOGIN,
  GET_ISSUING_REPORT,
  GET_ISSUING_REPORT_FAILED,
  GET_ISSUING_REPORT_SUCCESS,
  DOWNLOAD_SUCCESS,
  GET_MEMBER_BALANCE_REPORT,
  GET_MEMBER_BALANCE_REPORT_FAILED,
  GET_MEMBER_BALANCE_REPORT_SUCCESS,
  GET_NON_MEMBER_BALANCE_REPORT,
  GET_NON_MEMBER_BALANCE_REPORT_FAILED,
  GET_NON_MEMBER_BALANCE_REPORT_SUCCESS,
  GET_POINT_TRANSACTION_REPORT,
  GET_POINT_TRANSACTION_REPORT_FAILED,
  GET_POINT_TRANSACTION_REPORT_SUCCESS,
  GET_REDEEM_REPORT,
  GET_REDEEM_REPORT_FAILED,
  GET_REDEEM_REPORT_SUCCESS,
  GET_REFERRAL_REPORT,
  GET_REFERRAL_REPORT_FAILED,
  GET_REFERRAL_REPORT_SUCCESS,
  GET_VOUCHER_BALANCE_REPORT,
  GET_VOUCHER_BALANCE_REPORT_FAILED,
  GET_VOUCHER_BALANCE_REPORT_SUCCESS,
  GET_POINT_TRANSFER_REPORT,
  GET_POINT_TRANSFER_REPORT_FAILED,
  GET_POINT_TRANSFER_REPORT_SUCCESS,
  GET_PARTNER_REPORT,
  GET_PARTNER_REPORT_SUCCESS,
  GET_PARTNER_REPORT_FAILED,
  GET_POINTS_TRANSACTION_HISTORY_REPORT,
  GET_POINTS_TRANSACTION_HISTORY_REPORT_SUCCESS,
  GET_POINTS_TRANSACTION_HISTORY_REPORT_FAILED,
  GET_POINTS_VOUCHER_REDEMPTION_1_REPORT,
  GET_POINTS_VOUCHER_REDEMPTION_1_REPORT_SUCCESS,
  GET_POINTS_VOUCHER_REDEMPTION_1_REPORT_FAILED,
  GET_POINTS_VOUCHER_REDEMPTION_2_REPORT,
  GET_POINTS_VOUCHER_REDEMPTION_2_REPORT_SUCCESS,
  GET_POINTS_VOUCHER_REDEMPTION_2_REPORT_FAILED,
  MEMBER_DELINQUENCY_REPORT,
  MEMBER_DELINQUENCY_REPORT_SUCCESS,
  MEMBER_DELINQUENCY_REPORT_FAILED,
  GET_OWN_VOUCHER_REPORT,
  GET_OWN_VOUCHER_REPORT_SUCCESS,
  GET_OWN_VOUCHER_REPORT_FAILED,
} from "../../constants/ActionTypes";
import {
  issuingReportApi,
  memberBalanceApi,
  pointTransactionApi,
  redeemReportApi,
  referralReportApi,
  voucherBalanceApi,
  pointTransferApi,
  reportPartnerApi,
  pointsTransactionHistoryReportApi,
  pointsVoucherRedemption1ReportApi,
  pointsVoucherRedemption2ReportApi,
  nonMemberBalanceApi,
  memberDelinquencyApi,
  ownVoucherApi,
} from "../api/Report";

function* fetchIssuingReport({ payload }) {
  if (payload != null) {
    try {
      const searchMembersData = yield call(issuingReportApi, payload);
      if (searchMembersData.data.abstractResponse.responseStatus === "INQ000") {
        if (payload.isDownload) {
          yield put({
            type: DOWNLOAD_SUCCESS,
            payload: searchMembersData.data,
          });
        } else {
          yield put({
            type: GET_ISSUING_REPORT_SUCCESS,
            payload: searchMembersData.data,
          });
        }
      } else {
        yield put({
          type: GET_ISSUING_REPORT_FAILED,
          payload: searchMembersData.data.abstractResponses.responseMessage,
        });
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
          yield put({
            type: BACK_TO_LOGIN,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        } else {
          yield put({
            type: GET_ISSUING_REPORT_FAILED,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        }
      } else {
        yield put({
          type: GET_ISSUING_REPORT_FAILED,
          payload: "Sorry, this feature is not accessible at this time.",
        });
      }
    }
  }
}

function* fetchRedeemReport({ payload }) {
  if (payload != null) {
    try {
      const searchMembersData = yield call(redeemReportApi, payload);
      if (searchMembersData.data.abstractResponse.responseStatus === "INQ000") {
        if (payload.isDownload) {
          yield put({
            type: DOWNLOAD_SUCCESS,
            payload: searchMembersData.data,
          });
        } else {
          yield put({
            type: GET_REDEEM_REPORT_SUCCESS,
            payload: searchMembersData.data,
          });
        }
      } else {
        yield put({
          type: GET_REDEEM_REPORT_FAILED,
          payload: searchMembersData.data.abstractResponses.responseMessage,
        });
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
          yield put({
            type: BACK_TO_LOGIN,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        } else {
          yield put({
            type: GET_REDEEM_REPORT_FAILED,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        }
      } else {
        yield put({
          type: GET_REDEEM_REPORT_FAILED,
          payload: "Sorry, this feature is not accessible at this time.",
        });
      }
    }
  }
}

function* fetchMemberBalanceReport({ payload }) {
  if (payload != null) {
    try {
      const searchMembersData = yield call(memberBalanceApi, payload);
      if (searchMembersData.data.abstractResponse.responseStatus === "INQ000") {
        if (payload.isDownload) {
          yield put({
            type: DOWNLOAD_SUCCESS,
            payload: searchMembersData.data,
          });
        } else {
          yield put({
            type: GET_MEMBER_BALANCE_REPORT_SUCCESS,
            payload: searchMembersData.data,
          });
        }
      } else {
        yield put({
          type: GET_MEMBER_BALANCE_REPORT_FAILED,
          payload: searchMembersData.data.abstractResponses.responseMessage,
        });
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
          yield put({
            type: BACK_TO_LOGIN,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        } else {
          yield put({
            type: GET_MEMBER_BALANCE_REPORT_FAILED,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        }
      } else {
        yield put({
          type: GET_MEMBER_BALANCE_REPORT_FAILED,
          payload: "Sorry, this feature is not accessible at this time.",
        });
      }
    }
  }
}

function* fetchNonMemberBalanceReport({ payload }) {
  if (payload != null) {
    try {
      const searchNonMembersData = yield call(nonMemberBalanceApi, payload);
      if (
        searchNonMembersData.data.abstractResponse.responseStatus === "INQ000"
      ) {
        if (payload.isDownload) {
          yield put({
            type: DOWNLOAD_SUCCESS,
            payload: searchNonMembersData.data,
          });
        } else {
          yield put({
            type: GET_NON_MEMBER_BALANCE_REPORT_SUCCESS,
            payload: searchNonMembersData.data,
          });
        }
      } else {
        yield put({
          type: GET_NON_MEMBER_BALANCE_REPORT_FAILED,
          payload: searchNonMembersData.data.abstractResponses.responseMessage,
        });
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
          yield put({
            type: BACK_TO_LOGIN,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        } else {
          yield put({
            type: GET_NON_MEMBER_BALANCE_REPORT_FAILED,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        }
      } else {
        yield put({
          type: GET_NON_MEMBER_BALANCE_REPORT_FAILED,
          payload: "Sorry, this feature is not accessible at this time.",
        });
      }
    }
  }
}

function* fetchVoucherBalanceReport({ payload }) {
  if (payload != null) {
    try {
      const searchMembersData = yield call(voucherBalanceApi, payload);
      if (searchMembersData.data.abstractResponse.responseStatus === "INQ000") {
        if (payload.isDownload) {
          yield put({
            type: DOWNLOAD_SUCCESS,
            payload: searchMembersData.data,
          });
        } else {
          yield put({
            type: GET_VOUCHER_BALANCE_REPORT_SUCCESS,
            payload: searchMembersData.data,
          });
        }
      } else {
        yield put({
          type: GET_VOUCHER_BALANCE_REPORT_FAILED,
          payload: searchMembersData.data.abstractResponses.responseMessage,
        });
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
          yield put({
            type: BACK_TO_LOGIN,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        } else {
          yield put({
            type: GET_VOUCHER_BALANCE_REPORT_FAILED,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        }
      } else {
        yield put({
          type: GET_VOUCHER_BALANCE_REPORT_FAILED,
          payload: "Sorry, this feature is not accessible at this time.",
        });
      }
    }
  }
}

function* fetchOwnVoucherReport({ payload }) {
  console.log("__MEMBERSDATA1");

  if (payload != null) {
    try {
      const searchMembersData = yield call(ownVoucherApi, payload);
      console.log("__MEMBERSDATA", searchMembersData);
      if (searchMembersData.data.abstractResponse.responseStatus === "INQ000") {
        if (payload.isDownload) {
          yield put({
            type: DOWNLOAD_SUCCESS,
            payload: searchMembersData.data,
          });
        } else {
          yield put({
            type: GET_OWN_VOUCHER_REPORT_SUCCESS,
            payload: searchMembersData.data,
          });
        }
      } else {
        yield put({
          type: GET_OWN_VOUCHER_REPORT_FAILED,
          payload: searchMembersData.data.abstractResponses.responseMessage,
        });
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
          yield put({
            type: BACK_TO_LOGIN,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        } else {
          yield put({
            type: GET_OWN_VOUCHER_REPORT_FAILED,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        }
      } else {
        yield put({
          type: GET_OWN_VOUCHER_REPORT_FAILED,
          payload: "Sorry, this feature is not accessible at this time.",
        });
      }
    }
  }
}

function* fetchReferralReport({ payload }) {
  if (payload != null) {
    try {
      const searchMembersData = yield call(referralReportApi, payload);
      if (searchMembersData.data.abstractResponse.responseStatus === "INQ000") {
        if (payload.isDownload) {
          yield put({
            type: DOWNLOAD_SUCCESS,
            payload: searchMembersData.data,
          });
        } else {
          yield put({
            type: GET_REFERRAL_REPORT_SUCCESS,
            payload: searchMembersData.data,
          });
        }
      } else {
        yield put({
          type: GET_REFERRAL_REPORT_FAILED,
          payload: searchMembersData.data.abstractResponses.responseMessage,
        });
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
          yield put({
            type: BACK_TO_LOGIN,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        } else {
          yield put({
            type: GET_REFERRAL_REPORT_FAILED,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        }
      } else {
        yield put({
          type: GET_REFERRAL_REPORT_FAILED,
          payload: "Sorry, this feature is not accessible at this time.",
        });
      }
    }
  }
}

function* fetchPointTransaction({ payload }) {
  if (payload != null) {
    try {
      const searchMembersData = yield call(pointTransactionApi, payload);
      if (searchMembersData.data.abstractResponse.responseStatus === "INQ000") {
        if (payload.isDownload) {
          yield put({
            type: DOWNLOAD_SUCCESS,
            payload: searchMembersData.data,
          });
        } else {
          yield put({
            type: GET_POINT_TRANSACTION_REPORT_SUCCESS,
            payload: searchMembersData.data,
          });
        }
      } else {
        yield put({
          type: GET_POINT_TRANSACTION_REPORT_FAILED,
          payload: searchMembersData.data.abstractResponses.responseMessage,
        });
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
          yield put({
            type: BACK_TO_LOGIN,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        } else {
          yield put({
            type: GET_POINT_TRANSACTION_REPORT_FAILED,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        }
      } else {
        yield put({
          type: GET_POINT_TRANSACTION_REPORT_FAILED,
          payload: "Sorry, this feature is not accessible at this time.",
        });
      }
    }
  }
}

function* fetchPointTransfer({ payload }) {
  if (payload != null) {
    try {
      const searchMembersData = yield call(pointTransferApi, payload);
      if (searchMembersData.data.abstractResponse.responseStatus === "INQ000") {
        if (payload.isDownload) {
          yield put({
            type: DOWNLOAD_SUCCESS,
            payload: searchMembersData.data,
          });
        } else {
          yield put({
            type: GET_POINT_TRANSFER_REPORT_SUCCESS,
            payload: searchMembersData.data,
          });
        }
      } else {
        yield put({
          type: GET_POINT_TRANSFER_REPORT_FAILED,
          payload: searchMembersData.data.abstractResponses.responseMessage,
        });
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
          yield put({
            type: BACK_TO_LOGIN,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        } else {
          yield put({
            type: GET_POINT_TRANSFER_REPORT_FAILED,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        }
      } else {
        yield put({
          type: GET_POINT_TRANSFER_REPORT_FAILED,
          payload: "Sorry, this feature is not accessible at this time.",
        });
      }
    }
  }
}

function* fetchReportPartner({ payload }) {
  if (payload != null) {
    try {
      const searchMembersData = yield call(reportPartnerApi, payload);
      if (searchMembersData.data.abstractResponse.responseStatus === "INQ000") {
        if (payload.isDownload) {
          yield put({
            type: DOWNLOAD_SUCCESS,
            payload: searchMembersData.data,
          });
        } else {
          yield put({
            type: GET_PARTNER_REPORT_SUCCESS,
            payload: searchMembersData.data,
          });
        }
      } else {
        yield put({
          type: GET_PARTNER_REPORT_FAILED,
          payload: searchMembersData.data.abstractResponses.responseMessage,
        });
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
          yield put({
            type: BACK_TO_LOGIN,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        } else {
          yield put({
            type: GET_PARTNER_REPORT_FAILED,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        }
      } else {
        yield put({
          type: GET_PARTNER_REPORT_FAILED,
          payload: "Sorry, this feature is not accessible at this time.",
        });
      }
    }
  }
}

function* fetchPointsTransactionHistoryReport({ payload }) {
  if (payload != null) {
    try {
      const searchMembersData = yield call(
        pointsTransactionHistoryReportApi,
        payload
      );
      if (searchMembersData.data.abstractResponse.responseStatus === "INQ000") {
        if (payload.isDownload) {
          yield put({
            type: DOWNLOAD_SUCCESS,
            payload: searchMembersData.data,
          });
        } else {
          yield put({
            type: GET_POINTS_TRANSACTION_HISTORY_REPORT_SUCCESS,
            payload: searchMembersData.data,
          });
        }
      } else if (
        searchMembersData.data.abstractResponse.responseStatus === "ERROR016"
      ) {
        let resp = {
          result: [],
          recordInfo: {},
        };
        yield put({
          type: GET_POINTS_TRANSACTION_HISTORY_REPORT_SUCCESS,
          payload: resp,
        });
      } else {
        yield put({
          type: GET_POINTS_TRANSACTION_HISTORY_REPORT_FAILED,
          payload: searchMembersData.data.abstractResponses.responseMessage,
        });
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
          yield put({
            type: BACK_TO_LOGIN,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        } else {
          yield put({
            type: GET_POINTS_TRANSACTION_HISTORY_REPORT_FAILED,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        }
      } else {
        yield put({
          type: GET_POINTS_TRANSACTION_HISTORY_REPORT_FAILED,
          payload: "Sorry, this feature is not accessible at this time.",
        });
      }
    }
  }
}

function* fetchPointsVoucherRedemption1Report({ payload }) {
  if (payload != null) {
    try {
      const searchMembersData = yield call(
        pointsVoucherRedemption1ReportApi,
        payload
      );
      if (searchMembersData.data.abstractResponse.responseStatus === "INQ000") {
        if (payload.isDownload) {
          yield put({
            type: DOWNLOAD_SUCCESS,
            payload: searchMembersData.data,
          });
        } else {
          yield put({
            type: GET_POINTS_VOUCHER_REDEMPTION_1_REPORT_SUCCESS,
            payload: searchMembersData.data,
          });
        }
      } else if (
        searchMembersData.data.abstractResponse.responseStatus === "ERROR016"
      ) {
        let resp = {
          result: [],
          recordInfo: {},
        };
        yield put({
          type: GET_POINTS_VOUCHER_REDEMPTION_1_REPORT_SUCCESS,
          payload: resp,
        });
      } else {
        yield put({
          type: GET_POINTS_VOUCHER_REDEMPTION_1_REPORT_FAILED,
          payload: searchMembersData.data.abstractResponses.responseMessage,
        });
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
          yield put({
            type: BACK_TO_LOGIN,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        } else {
          yield put({
            type: GET_POINTS_VOUCHER_REDEMPTION_1_REPORT_FAILED,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        }
      } else {
        yield put({
          type: GET_POINTS_VOUCHER_REDEMPTION_1_REPORT_FAILED,
          payload: "Sorry, this feature is not accessible at this time.",
        });
      }
    }
  }
}

function* fetchPointsVoucherRedemption2Report({ payload }) {
  if (payload != null) {
    try {
      const searchMembersData = yield call(
        pointsVoucherRedemption2ReportApi,
        payload
      );
      if (searchMembersData.data.abstractResponse.responseStatus === "INQ000") {
        if (payload.isDownload) {
          yield put({
            type: DOWNLOAD_SUCCESS,
            payload: searchMembersData.data,
          });
        } else {
          yield put({
            type: GET_POINTS_VOUCHER_REDEMPTION_2_REPORT_SUCCESS,
            payload: searchMembersData.data,
          });
        }
      } else if (
        searchMembersData.data.abstractResponse.responseStatus === "ERROR016"
      ) {
        let resp = {
          result: [],
          recordInfo: {},
        };
        yield put({
          type: GET_POINTS_VOUCHER_REDEMPTION_2_REPORT_SUCCESS,
          payload: resp,
        });
      } else {
        yield put({
          type: GET_POINTS_VOUCHER_REDEMPTION_2_REPORT_FAILED,
          payload: searchMembersData.data.abstractResponses.responseMessage,
        });
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
          yield put({
            type: BACK_TO_LOGIN,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        } else {
          yield put({
            type: GET_POINTS_VOUCHER_REDEMPTION_2_REPORT_FAILED,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        }
      } else {
        yield put({
          type: GET_POINTS_VOUCHER_REDEMPTION_2_REPORT_FAILED,
          payload: "Sorry, this feature is not accessible at this time.",
        });
      }
    }
  }
}

function* fetchMemberDelinquencyReport({ payload }) {
  if (payload != null) {
    try {
      const responseData = yield call(memberDelinquencyApi, payload);
      if (responseData.data.abstractResponse.responseStatus === "INQ000") {
        if (payload.isDownload) {
          yield put({ type: DOWNLOAD_SUCCESS, payload: responseData.data });
        } else {
          yield put({
            type: MEMBER_DELINQUENCY_REPORT_SUCCESS,
            payload: responseData.data,
          });
        }
      } else {
        yield put({
          type: MEMBER_DELINQUENCY_REPORT_FAILED,
          payload: responseData.data.abstractResponse.responseMessage,
        });
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data.abstractResponse === undefined) {
          yield put({
            type: MEMBER_DELINQUENCY_REPORT_FAILED,
            payload: "Sorry, this feature is not accessible at this time.",
          });
        } else if (
          error.response.data.abstractResponse.responseStatus === "AUTH001"
        ) {
          yield put({
            type: BACK_TO_LOGIN,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        } else {
          yield put({
            type: MEMBER_DELINQUENCY_REPORT_FAILED,
            payload: error.response.data.abstractResponse.responseMessage,
          });
        }
      } else {
        yield put({
          type: MEMBER_DELINQUENCY_REPORT_FAILED,
          payload: "Sorry, this feature is not accessible at this time.",
        });
      }
    }
  }
}

export function* getIssuingData() {
  yield takeEvery(GET_ISSUING_REPORT, fetchIssuingReport);
}

export function* getRedeemData() {
  yield takeEvery(GET_REDEEM_REPORT, fetchRedeemReport);
}

export function* getMemberBalanceData() {
  yield takeEvery(GET_MEMBER_BALANCE_REPORT, fetchMemberBalanceReport);
}

export function* getNonMemberBalanceData() {
  yield takeEvery(GET_NON_MEMBER_BALANCE_REPORT, fetchNonMemberBalanceReport);
}

export function* getVoucherBalanceData() {
  yield takeEvery(GET_VOUCHER_BALANCE_REPORT, fetchVoucherBalanceReport);
}

export function* getOwnVoucherData() {
  yield takeEvery(GET_OWN_VOUCHER_REPORT, fetchOwnVoucherReport);
}

export function* getReferralMemberData() {
  yield takeEvery(GET_REFERRAL_REPORT, fetchReferralReport);
}

export function* getPointTransactionData() {
  yield takeEvery(GET_POINT_TRANSACTION_REPORT, fetchPointTransaction);
}

export function* getPointTransferData() {
  yield takeEvery(GET_POINT_TRANSFER_REPORT, fetchPointTransfer);
}

export function* getPartnerData() {
  yield takeEvery(GET_PARTNER_REPORT, fetchReportPartner);
}

export function* getPointTransactionHistoryData() {
  yield takeEvery(
    GET_POINTS_TRANSACTION_HISTORY_REPORT,
    fetchPointsTransactionHistoryReport
  );
}

export function* getPointVoucherRedemption1Data() {
  yield takeEvery(
    GET_POINTS_VOUCHER_REDEMPTION_1_REPORT,
    fetchPointsVoucherRedemption1Report
  );
}

export function* getPointVoucherRedemption2Data() {
  yield takeEvery(
    GET_POINTS_VOUCHER_REDEMPTION_2_REPORT,
    fetchPointsVoucherRedemption2Report
  );
}

export function* getMemberDelinquencyData() {
  yield takeEvery(MEMBER_DELINQUENCY_REPORT, fetchMemberDelinquencyReport);
}

// yield all
export default function* rootSaga() {
  yield all([
    fork(getIssuingData),
    fork(getRedeemData),
    fork(getMemberBalanceData),
    fork(getNonMemberBalanceData),
    fork(getVoucherBalanceData),
    fork(getReferralMemberData),
    fork(getPointTransactionData),
    fork(getPointTransferData),
    fork(getPartnerData),
    fork(getPointTransactionHistoryData),
    fork(getPointVoucherRedemption1Data),
    fork(getPointVoucherRedemption2Data),
    fork(getMemberDelinquencyData),
    fork(getOwnVoucherData),
  ]);
}
